import React from 'react';
import cx from 'classnames';
import './Button.scss';

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant: 'primary' | 'text' | 'outlined' | 'close' | 'transparent' | 'framed' | 'close-dark-red';
  size: 'md' | 'lg' | 'xl' | 'xxl';
  title?: string;
  withDisclosure?: boolean;
  withReversedDisclosure?: boolean;
  withShadow?: boolean;
  isInactive?: boolean;
  isExternal?: boolean;
  image?: React.ReactNode;
}

export const Button: React.FunctionComponent<Props> = (props) => {
  const { variant, size, image, ...htmlProps } = props;
  return (
    <button
      {...htmlProps}
      className={cx(
        {
          'button-transparent': variant === 'transparent',
          'button-close': variant === 'close',
          'button-framed': variant === 'framed',
          'button-close-dark-red': variant === 'close-dark-red',
          'text-white': variant === 'primary',
          'border border-brand': variant === 'outlined',
          'button-lg': size === 'lg',
          'button-md': size === 'md',
          'button-xl': size === 'xl',
          'button-xxl': size === 'xxl'
        },
        'rounded',
        'button-green',
        props.withShadow && 'button-shadow',
        props.isInactive && 'button-inactive',
        props.isExternal && 'button-external',
        props.className
      )}
    >
      {image ? image : null}

      {props.title && (
        <span className="button__text">
          {variant === 'close' && (
            <img
              className="button__icon-close"
              src={require('../../assets-shared/close-button-red.png').default}
              alt="RBI"
            />
          )}

          {props.withDisclosure && props.withReversedDisclosure && (
            <img
              className="button__icon-disclosure-leading"
              src={require('../../assets-shared/arrow-left-gray.png').default}
              alt="RBI"
            />
          )}
          {props.title}
          {props.withDisclosure && !props.withReversedDisclosure && (
            <img
              className="button__icon-disclosure"
              src={require('../../assets-shared/arrow-right-white.png').default}
              alt="RBI"
            />
          )}
          {props.isExternal && (
            <img
              className="button__icon-external"
              src={require('../../assets-shared/externalURL.png').default}
              alt="RBI"
            />
          )}
        </span>
      )}
      {props.children}
    </button>
  );
};
