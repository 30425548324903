import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { SectionHeader } from '../../components/SectionHeader';
import './WhatsNewPage.scss';
import { TabLayout } from '../../layouts/TabLayout';
import { Button } from '../../components/Button';

interface Props extends RouteComponentProps<{}> {}

export const WhatsNewPage: React.FunctionComponent<Props> = (props) => {
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);

  return (
    <TabLayout
      isDropdownVisible={isDropdownVisible}
      selectedIndex="3"
      callback={(isVisible) => setIsDropdownVisible(isVisible)}
    >
      <SectionHeader
        title="What´s new?"
        subtitle="Explore our new courses or check out the ongoing activities in the Procurement Academy in the RBI Learning and Development Room."
        history={props.history}
        location={props.location}
        match={props.match}
      />
      <Button
        className="whatsnew__button"
        type="submit"
        size="xxl"
        variant="primary"
        title="Visit Learning and Development Room"
        withShadow={true}
        isExternal={true}
        onClick={() => {
          window.open('https://rbinternational.sharepoint.com/sites/LearnDev', '_blank');
        }}
      />

      <Button
        className="whatsnew__button whatsnew__button-image"
        type="submit"
        size="xl"
        variant="primary"
        isExternal={true}
        title={'Sustainability and ESG'}
        withShadow={true}
        onClick={() => {
          window.open(
            'https://rb.csod.com/ui/lms-learning-details/app/curriculum/e8f35b38-ea66-4e6b-881e-575df416bde6',
            '_blank'
          );
        }}
        image={<img src={require('../../assets-shared/ai1.jpg').default} alt="" />}
      />

      <Button
        className="whatsnew__button whatsnew__button-image"
        type="submit"
        size="xl"
        variant="primary"
        isExternal={true}
        title={'Intro to ChatGPT'}
        withShadow={true}
        onClick={() => {
          window.open(
            'https://rb.csod.com/ui/lms-learning-details/app/material/0cd4534c-7285-4660-a7fd-bdb18d89488d',
            '_blank'
          );
        }}
        image={<img src={require('../../assets-shared/ai2.jpeg').default} alt="" />}
      />

      <Button
        className="whatsnew__button whatsnew__button-image"
        type="submit"
        size="xl"
        variant="primary"
        isExternal={true}
        title={'Prompting in ChatGPT'}
        withShadow={true}
        onClick={() => {
          window.open(
            'https://rb.csod.com/ui/lms-learning-details/app/material/9c499fc5-257e-497a-b8eb-b419fbe4443f',
            '_blank'
          );
        }}
        image={<img src={require('../../assets-shared/ai2.jpeg').default} alt="" />}
      />
    </TabLayout>
  );
};
